
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






























































































::v-deep {
  .section-everywhere .section-row__content__title {
    display: none;
  }

  .card-small,
  .dominopill,
  .everywhere,
  .gigateam__centered-block {
    background-color: #fff !important;
  }

  .steps,
  .services-list {
    background-color: $c-white-dark !important;
  }
}

.gigateam__centered-block {
  background-color: $c-white-dark;
}

.gigateam__dominopill {
  overflow: inherit !important;

  ::v-deep {
    .dominopill__card__icon {
      width: auto;
    }
  }

  + section {
    position: relative;
    z-index: 10;
  }
}

.gigateam__hero {
  .hero__slides {
    z-index: 10;
  }

  ::v-deep {
    .hero__slides__item__picture {
      @include mq(l) {
        left: inherit;
        width: 80%;
      }
    }

    .hero__slides__item__title.title {
      font-size: 2.8em / $body-text-s * 1em;

      @include mq(m) {
        font-size: 3.2em / $body-text-m * 1em;
      }

      @include mq(l) {
        font-size: 4.8em / $body-text-l * 1em;
      }
    }

    .icon {
      @include mq(l) {
        margin-bottom: 3.7rem;

        img {
          min-width: 277px;
          max-width: inherit;
          height: auto !important;
        }
      }
    }
  }

  @include mq(l) {
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 66%;
      height: 100%;

      // prettier-ignore
      background: linear-gradient(89deg, #0B111D 40%, rgba(11, 17, 29, 0%) 99.22%);
    }
  }
}
