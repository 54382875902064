
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































.everywhere {
  background-color: white;
}
::v-deep .section-row .section-row__content__title {
  display: none;
}

.section-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  ::v-deep {
    .section-row__picture {
      position: relative;

      @include mq($until: 'l') {
        overflow: hidden;
        margin-bottom: 0;
      }
    }

    .section-row__content__title {
      display: none;
    }

    .section-cta__link span {
      display: none;
    }
  }
}

.section-everywhere {
  ::v-deep .section-row__inner {
    align-items: center;
  }

  ::v-deep .section-row__picture {
    position: relative;

    @include mq('l') {
      min-height: 70rem;
    }
  }

  ::v-deep .speedlines {
    top: $spacing * 6;
    margin-top: -$spacing * 5;

    @include mq('l') {
      top: $spacing * 8;
      margin-top: 0;
    }
  }

  .section-cta-legacy {
    margin-bottom: $spacing * 2;
    padding-bottom: $spacing * 2;
    border-bottom: 1px solid $c-blue-light-pale;
  }

  ::v-deep {
    .section-cta-outer {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.section-row__speedlines {
  @include mq($until: 'l') {
    position: relative;
    height: 45rem;
  }
}

.speedline-badge {
  position: absolute;
  right: 0;
  bottom: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30rem;
  height: 13rem;
  background-color: $c-purple;
  border-radius: 8rem;
  transform: rotate(-57deg);
  transform-origin: 50% 50%;

  &::after {
    content: '';
    display: block;
    width: 6rem;
    height: 6rem;
    margin-right: 3rem;
    transform: rotate(57deg);
    background-image: url('/static/@shared/internet/everywhere.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include mq('l') {
      width: 8rem;
      height: 8rem;
    }
  }

  @include mq($until: 'l') {
    right: -30%;
    transform: rotate(-57deg) scale(0.4);
  }
}

.hub-net__title {
  padding-bottom: 0 !important;
  text-align: center;
}
